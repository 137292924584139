.image-container {
  display: flex;
  align-items: flex-start;
}

.image {
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}

.original-image img {
  display: block;
  width: 100%;
  height: auto;
}

.magnifier {
  position: absolute;
  display: none;
  width: 200px; /* Adjust the width of the magnified image container */
  height: 200px; /* Adjust the height of the magnified image container */
  border: 2px solid #000;
  background-color: #fff;
  pointer-events: none;
}

.image.zoomed .magnifier {
  display: block;
}

.magnifier img {
  position: absolute;
  width: 100%;
  height: auto;
  transform: scale(2); /* Adjust the magnification level */
  transform-origin: top left;
}

.zoom-instruction {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #333;
  padding: 10px;
}
