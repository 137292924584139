.cardimg:hover {
  scale: 1.1;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  border-radius: 5px;
  object-fit: fill;
}

.product-img:hover {
  border: 1px solid #000;
  cursor: pointer;
}
.childrenNavbar:hover {
  color: #0099cc;
  text-decoration: underline;
  cursor: pointer;
}

.createdBy:hover {
  color: #0099cc;
}
.cardimg {
  transition: all 0.5s ease-in-out;
}

.cardimg:hover {
  transition: all 0.5s ease-in-out;

  scale: 1.1;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  object-fit: fill;
}
.breadCrumb:hover {
  text-decoration: underline;
}

.CartTitle:hover {
  color: "orange";
}
