@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&family=Roboto:wght@300;400&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
}

code {
  font-family: "Courier New", monospace;
}

* {
  margin: 0;
  padding: 0;
}

html,
body,
:root {
  height: "100%";
}
